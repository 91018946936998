import React, { useEffect, useRef, useState } from "react";
import { FormProvider, useForm, useWatch } from "react-hook-form";
import * as Styled from "./Form.styled";
import Input from "../../../components/Form/Input/Input";
import Button from "../../../components/Button/Button";
import Checkbox from "../../../components/Form/Checkbox/Checkbox";
import axios from "axios";
import Recaptcha from "../../../components/Form/Recaptcha/Recaptcha";
import { MainInfoCheckboxes } from "./Form.styled";
import SuccessBoxFormPage from "./SuccessBoxFormPage";

type FormData = {
  s_property1: string;
  s_property2: string;
  s_email: string;
  s_property3?: string;
  s_uid: string;
  s_key: string;
  s_interface: string;
  s_type: string;
  s_encoded: string;
  s_group_1: string;
  s_property4: string;
  additionalFields?: boolean;
  obligatoryConsents?: boolean;
};

const FormFormPage = () => {
  const [reHash, setReHash] = useState<undefined | string | number>();
  const formRef = useRef<HTMLFormElement>(null);
  const methods = useForm<FormData>();
  const [working, setWorking] = useState(false);
  const [validationError, setValidationError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);

  const firstConsentValue = useWatch<any>({
    control: methods.control,
    name: "additionalFields.firstConsent",
  });
  const secondConsentValue = useWatch<any>({
    control: methods.control,
    name: "additionalFields.secondConsent",
  });
  const [checkboxesError, setCheckboxesError] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);

  useEffect(() => {
    if (!isSubmitted) {
      return;
    }
    if (firstConsentValue || secondConsentValue) {
      methods.clearErrors("obligatoryConsents");
      setCheckboxesError(false);
    } else {
      methods.setError("obligatoryConsents", {
        type: "obligatory consent not checked",
      });
      setCheckboxesError(true);
    }
  }, [isSubmitted, firstConsentValue, secondConsentValue]);

  const submitData = React.useCallback(() => {
    setValidationError(false);
    if (formRef.current) {
      const formData = new FormData(formRef.current);
      formData.delete("g-recaptcha-response");
      formData.delete("recaptcha");

      axios
        .post(`${process.env.GATSBY_FORM_PAGE_API}`, formData)
        .then((response) => {
          console.log(response.status);
          setWorking(false);
          setSuccess(true);
          setValidationError(false);
        })
        .catch((err) => {
          console.log(err);
          // setError(true);
          setSuccess(true);
          setValidationError(false);
          setWorking(false);
        });
    }
  }, []);

  const onButtonClick = React.useCallback(() => {
    setIsSubmitted(true);
    setWorking(true);
    setSuccess(false);
    setError(false);
    setReHash(Math.random());
  }, []);

  const preSubmitData = React.useCallback(() => {
    methods.handleSubmit(submitData, (d) => {
      setValidationError(true);
      setWorking(false);
    })();
  }, [formRef]);

  return (
    <FormProvider {...methods}>
      {!success && (
        <Styled.Form onSubmit={methods.handleSubmit(submitData)} ref={formRef}>
          <Styled.ContentWrapper type={"formPage"}>
            <input type="hidden" value="6696" {...methods.register("s_uid")} />
            <input
              type="hidden"
              {...methods.register("s_key")}
              value="*EEBB89B40BBC5F58D807AAF7B7C9D4C0547BE0FA"
            />
            <input
              type="hidden"
              {...methods.register("s_interface")}
              value="1"
            />
            <input type="hidden" {...methods.register("s_type")} value="HTML" />
            <input
              type="hidden"
              {...methods.register("s_encoded")}
              value="UTF-8"
            />
            <input type="hidden" {...methods.register("s_group_1")} value="1" />
            <input
              type="hidden"
              {...methods.register("s_property4")}
              value="TAK"
            />
            <Input
              required={"Field is required"}
              name={"s_property1"}
              label={"First name*"}
              color={"black"}
              autocomplete={false}
            />
            <Input
              required={"Field is required"}
              name={"s_property2"}
              label={"Last name*"}
              color={"black"}
              autocomplete={false}
            />
            <Input
              required={"Field is required"}
              name={"s_email"}
              label={"E-mail*"}
              color={"black"}
              autocomplete={false}
              pattern={{
                message: "Invalid e-mail address.",
                value:
                  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
              }}
            />
            <Input
              name={"s_property3"}
              label={"Phone"}
              color={"black"}
              autocomplete={false}
            />
          </Styled.ContentWrapper>
          <Styled.MainInfo color={"black"}>
            *My data can be used:
          </Styled.MainInfo>
          <MainInfoCheckboxes>
            <Checkbox name={"additionalFields.firstConsent"} color={"black"}>
              to receive the information of a marketing nature, including
              newsletters, information about events, by means of electronic
              communication - mark to receive promotional, advertising materials
              concerning Bayer Sp. z o.o., to the provided e-mail address. (I
              consent that my personal data may be processed for this purpose as
              described in more details in the{" "}
              <a
                href="https://www.bayer.com/pl/pl/polityka-prywatnosci"
                target={"_blank"}
                aria-label="Go to Privacy Statement"
              >
                Privacy Statement
              </a>
              ).
            </Checkbox>
            <Checkbox name={"additionalFields.secondConsent"} color={"black"}>
              to receive directing information of a marketing nature - check to
              receive promotional, advertising materials concerning the Bayer
              Sp. z o.o., via the provided telephone number. (I consent that my
              personal data may be processed for this purpose as described in
              more details in the{" "}
              <a
                href="https://www.bayer.com/pl/pl/polityka-prywatnosci"
                target={"_blank"}
                aria-label="Go to Privacy Statement"
              >
                Privacy Statement
              </a>
              ).
            </Checkbox>
            {checkboxesError && (
              <Styled.Error>
                You must accept at least one of the terms.
              </Styled.Error>
            )}
          </MainInfoCheckboxes>
          <Styled.SingleButtonWrapper>
            <Button
              text={working ? "Wait..." : "Submit"}
              disabled={working}
              onClick={onButtonClick}
              color={"blue"}
            />
          </Styled.SingleButtonWrapper>

          <Recaptcha executionHash={reHash} onSuccess={preSubmitData} />
        </Styled.Form>
      )}
      {success && <SuccessBoxFormPage />}
      {error && (
        <Styled.ErrorMessage>
          Server error occurred. Please try again later.
        </Styled.ErrorMessage>
      )}
      {validationError && (
        <Styled.ErrorMessage>
          Please fill out the form correctly.
        </Styled.ErrorMessage>
      )}
    </FormProvider>
  );
};

export default FormFormPage;
