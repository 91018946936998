import React from "react";
import * as Styled from "./Nav.styled";
import DHubLogo from "../../Logo/DHubLogo";
import ButtonLink from "../../Button/ButtonLink";
import BayerLogo from "../../Logo/BayerLogo";

const NavSimple: React.FunctionComponent = () => {
  return (
    <Styled.Container>
      <Styled.DHubWrapper type={"formPage"}>
        <DHubLogo color={"black"} />
      </Styled.DHubWrapper>
      <Styled.Row>
        <ButtonLink text="Go to main page" link={"/"} color="blue" />
        <Styled.BayerWrapper type={"formPage"}>
          <BayerLogo color={"black"} />
        </Styled.BayerWrapper>
      </Styled.Row>
    </Styled.Container>
  );
};

export default NavSimple;
