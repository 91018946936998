import React from "react";
import * as Styled from "./FormSection.styled";
import FormFormPage from "./components/FormFormPage";

const FormSectionFormPage = () => {
  return (
    <Styled.FormSection id="form" type={"formPage"}>
      <Styled.Container type={"formPage"}>
        <Styled.Title type={"formPage"}>Join our team!</Styled.Title>
        <Styled.Subtitle type={"formPage"}>
          and become <strong>one of us.</strong>
        </Styled.Subtitle>
        <FormFormPage />
      </Styled.Container>
    </Styled.FormSection>
  );
};

export default FormSectionFormPage;
