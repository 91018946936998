import React from "react";
import * as Styled from "./components/Nav.styled";
import NavSimple from "./components/NavSimple";

interface NavProps {}

const NavigationFormPage: React.FunctionComponent<NavProps> = () => {
  return (
    <Styled.Nav>
      <Styled.NavStatic>
        <NavSimple />
      </Styled.NavStatic>
    </Styled.Nav>
  );
};

export default NavigationFormPage;
