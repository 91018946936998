import React from "react";
import * as Styled from "./SuccessBox.styled";
import ButtonLink from "../../../components/Button/ButtonLink";

const SuccessBoxFormPage = () => {
  return (
    <Styled.SuccessBox id={"success-box"} className={"success-box"}>
      <Styled.Title color={"black"}>
        Your data has been successfully sent!
      </Styled.Title>
      <Styled.Message color={"black"}>Check your email!</Styled.Message>
    </Styled.SuccessBox>
  );
};

export default SuccessBoxFormPage;
