import * as React from "react";
import { FunctionComponent } from "react";
import FormSectionFormPage from "../sections/FormSection/FormSectionFormPage";
import FormPageLayout from "../layouts/FormPageLayout";

const FormPage: FunctionComponent = () => (
  <FormPageLayout>
    <FormSectionFormPage />
  </FormPageLayout>
);

export default FormPage;
