import React, { FunctionComponent, ReactElement } from "react";
import { ThemeProvider } from "styled-components";
import GlobalStyle from "../styles/GlobalStyle";
import ThemeStyle from "../styles/ThemeStyle";
import Seo from "../components/Seo/Seo";
import NavigationFormPage from "../components/Navigation/NavigationFormPage";
import Footer from "../sections/Footer/Footer";
import "../fonts/fonts.css";

interface IMainLayout {
  children: ReactElement | ReactElement[];
}

const FormPageLayout: FunctionComponent<IMainLayout> = ({ children }) => {
  return (
    <ThemeProvider theme={ThemeStyle}>
      <GlobalStyle />
      <Seo />
      <NavigationFormPage />
      <main className="main-content">{children}</main>
      <Footer type={"lime"} />
    </ThemeProvider>
  );
};

export default FormPageLayout;
